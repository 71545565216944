import request from '@/utils/request'

// 查询名片用户使用列表
export function listCardUser(query) {
  return request({
    url: '/card/card/user/list',
    method: 'get',
    params: query
  })
}

// 查询名片用户使用详细
export function getCardUser(id) {
  return request({
    url: '/card/card/user/' + id,
    method: 'get'
  })
}

// 新增名片用户使用
export function addCardUser(data) {
  return request({
    url: '/card/card/user',
    method: 'post',
    data: data
  })
}

// 修改名片用户使用
export function updateCardUser(data) {
  return request({
    url: '/card/card/user',
    method: 'put',
    data: data
  })
}

// 删除名片用户使用
export function delCardUser(id) {
  return request({
    url: '/card/card/user/' + id,
    method: 'delete'
  })
}
